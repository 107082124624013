<template>
  <div class="container-fluid main-color">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">Edit Record</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <place-holder v-if="isLoading"></place-holder>
        <div class="card">
          <div v-if="!isLoading" class="card-body">
            <form class="parsley-examples">
              <div class="row mb-3 mt-4">
                <div class="col-3">
                  <label for="name">
                    Question Name <span class="text-danger">*</span>
                  </label>
                </div>
                <div class="col-6">
                  <input
                    type="text"
                    class="form-control"
                    id="name"
                    name="name"
                    v-model="module.name"
                    :class="{ 'p-invalid': v$.module.name.$error }"
                  />
                  <v-errors
                    :serverErrors="errorFor('name')"
                    :vuelidateErrors="{
                      errors: v$.module.name.$errors,
                      value: 'Question name',
                    }"
                  ></v-errors>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-3">
                  <label for="remark">
                    Answer Type <span class="text-danger">*</span>
                  </label>
                </div>
                <div class="col-6">
                  <v-select
                    v-model="module.question_type"
                    label="name"
                    :options="question_types"
                    :selectable="
                      (options) => module.question_type != options.name
                    "
                    :clearable="false"
                    :class="{
                      'p-invalid': v$.module.question_type.$error,
                    }"
                  >
                  </v-select>
                  <v-errors
                    :serverErrors="errorFor('name')"
                    :vuelidateErrors="{
                      errors: v$.module.question_type.$errors,
                      value: 'Question type',
                    }"
                  ></v-errors>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-3">
                  <label for="remark">
                    Status <span class="text-danger">*</span>
                  </label>
                </div>
                <div class="col-6">
                  <v-select
                    v-model="module.status"
                    label="name"
                    :options="arr_status"
                    :selectable="(options) => module.status != options.name"
                    :clearable="false"
                    :class="{ 'p-invalid': v$.module.status.$error }"
                  >
                  </v-select>
                  <v-errors
                    :serverErrors="errorFor('name')"
                    :vuelidateErrors="{
                      errors: v$.module.status.$errors,
                      value: 'Question type',
                    }"
                  ></v-errors>
                </div>
              </div>

              <!-- end row -->
              <div class="row" v-if="hasPermissions(['ata-question-edit'])">
                <!-- <div class="col-6"></div>       -->
                <div class="col-md-12">
                  <div class="mt-3 text-center mb-3 ms-3">
                    <router-link
                      :to="{ name: 'after-trainer-question-assessment' }"
                    >
                      <button type="button" class="btn w-sm btn-secondary me-5">
                        Cancel
                      </button>
                    </router-link>
                    <button
                      :disabled="isLoading ? true : false"
                      type="button"
                      class="btn w-sm btn-success waves-effect waves-light px-3"
                      @click="questionUpdate()"
                    >
                      <span
                        v-if="isLoading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      {{ isLoading == true ? "Loading..." : "Save" }}
                    </button>
                  </div>
                </div>
                <!-- end col -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import validationErrors from "../../../mixins/validationErrors";
import userHasPermissions from "../../../mixins/userHasPermissions";
import { options } from "dropzone";

export default {
  setup() {
    const toast = useToast();
    return { v$: useVuelidate(), toast };
  },
  components: {
    vSelect,
  },
  mixins: [validationErrors, userHasPermissions],
  data() {
    return {
      question_types: ["typing", "radio"],
      arr_status: ["Active", "Inactive"],
      selectedType: null,
      selectedStatus: null,
      module: {
        name: "",
        question_type: "",
        status: "",
      },
      loading: false,
      isLoading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlHRIS: process.env.VUE_APP_COURSIA_BASE_URL,
    };
  },
  methods: {
    async questionEdit() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(
          `${this.baseUrl}admin/v2/assessment-question/${this.$route.params.id}`
        )
        .then((res) => {
          // console.log(res.data.data);
          this.module.name = res.data.data.name;
          this.module.question_type = res.data.data.question_type;
          this.module.status = res.data.data.status ? "Active" : "Inactive";
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.error = error.response;
          this.toast.error("Something went wrong.");
          this.loading = false;
          this.$Progress.finish();
        });
    },
    async questionUpdate() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        this.toast.error("Please fill all input!");
        return;
      }
      this.isLoading = true;
      let updateData = this.module;
      updateData.status = updateData.status == "Active" ? 1 : 0;
      await axios
        .put(
          `${this.baseUrl}admin/v2/assessment-question/${this.$route.params.id}`,
          updateData
        )
        .then(() => {
          this.toast.success("Successfully Assessment Question Updated.");
          this.$router.push({ name: "after-trainer-question-assessment" });
          this.isLoading = false;
        })
        .catch((error) => {
          this.error = error.response;
          this.toast.error("Something went wrong.");
          this.isLoading = false;
        });
    },
  },
  validations() {
    return {
      module: {
        name: { required },
        question_type: { required },
        status: { required },
      },
    };
  },
  async created() {
    await this.questionEdit();
  },
};
</script>

<style>
/* .custom-selectCSS {
  --vs-dropdown-min-width: 160px;
  --vs-dropdown-max-height: 200px;
}
.custom-v-select .vs__dropdown-toggle .vs__open-indicator::before {
  display: none;
} */
</style>
